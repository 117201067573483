const Injections = () => {
	return (
		<>
			<h1>Injections</h1>
			<div className="border shadow rounded table-responsive">
				<table className="table table-striped data-table">
					<thead>
						<tr>
							<th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
							<th><a onclick="sortTable(1,1)" className="cursor-pointer text-nowrap">HSN Code</a></th>
							<th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
						</tr>
					</thead>
					<tbody>
						<tr><td>AMOXYCILLIN AND CLAVULANATE POTASSIUM  INJECTION â€“ 1.2 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>AMPICILLIN &amp; SULBACTAM FOR INJECTION-1.5 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>AMPICILLIN &amp; SULBACTAM FOR INJECTION-0.750 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>AMPICILLIN INJECTION â€“ 1 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFOPERAZONE SODIUM INJECTION- 1 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFOTAXIME FOR INJECTION-1 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFEPIME AND TAZOBACTAM INJECTION- 1.125 MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFEPIME FOR INJECTION 1 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFTAZIDIME &amp; TAZOBACTAM For INJECTION â€“ 1.125 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFTAZIDIME INJECTION â€“ 1 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFTRIAXONE AND SULBACTAM INJECTION- 1.5 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFTRIAXONE AND  Tazobactum -1.125 MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFTRIAXONE AND SULBACTAM INJECTION- 750 MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFTRIAXONE INJECTION â€“ 1 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>MEROPENEM INJECTION- 1 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>MEROPENEM INJECTION- 500 MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>PIPERACILLIN &amp; TAZOBACTAM INJECTION-2.250 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>PIPERACILLIN &amp; TAZOBACTAM INJECTION-4.5 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>OMERPRAZOLE FOR INJECTION 40MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>PENTOPRAZOLE INJECTION</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFOPERAZONE SODIUM &amp; SULBACTUM INJECTION- 1.5 GM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>AMIKACIN INJECTION 100MG/2ML</td><td>30042019</td><td>N1</td></tr>
						<tr><td>AMIKACIN INJECTION 250MG/2ML</td><td>30042019</td><td>N1</td></tr>
						<tr><td>AMIKACIN INJECTION 500MG/2ML</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CITICOLINE INJECTION 250MG/2ML</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CITICOLINE INJECTION 250MG/4ML</td><td>30042019</td><td>N1</td></tr>
						<tr><td>ENOXAPARIN SODIUM INJECTION 0.4</td><td>30042019</td><td>N1</td></tr>
						<tr><td>ENOXAPARIN SODIUM INJECTION 0.6</td><td>30042019</td><td>N1</td></tr>
						<tr><td>ENOXAPARIN SODIUM INJECTION 0.8</td><td>30042019</td><td>N1</td></tr>
						<tr><td>REMDESIVIR FO RINJECTION 100MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>KETOROLAC TROMRTHAMINE INJECTION USP 30MG/ML</td><td>30042019</td><td>N1</td></tr>
						<tr><td>L-ORNITHINE L-ASPARATATE INJECTION 5G/1ML</td><td>30042019</td><td>N1</td></tr>
						<tr><td>VITAMIN B-COMPLEX INJECTION</td><td>30042019</td><td>N1</td></tr>
						<tr><td>IRON SUCROSE INJECTION USOP 20MG</td><td>30042019</td><td>N1</td></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
export default Injections