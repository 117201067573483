import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";

const Nav = () => {
    const [lang, setLang] = useState('en')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="container">
            <Helmet htmlAttributes={{ lang : lang }}/> 
            <nav className="navbar navbar-expand-lg bg-white navbar-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="72px" height="72" version="1.1" 
                        style={{width:'72px',height:'72px',shapeRendering:'geometricPrecision', textRendering:'geometricPrecision', imageRendering:'optimizeQuality', fillRule:'evenodd',clipRule:'evenodd'}}
                         viewBox="0 0 29700 21000" 
                        xmlnsXlink="http://www.w3.org/1999/xlink">
                            <defs>
                                <style type="text/css">
                                    {`.fil0 {fill:#F39324}
                                    .fil1 {fill:#00A0E3}`}
                                </style>
                    </defs>
                            <g id="Layer_x0020_1">
                                <metadata id="CorelCorpID_0Corel-Layer"></metadata>
                                <g id="_2453694436000">
                                    <path className="fil0" d="M20295 7537l-1077 690c-822,527 -1591,1014 -2424,1473 -991,545 -2391,1320 -3489,1423 -371,41 -413,-164 -350,-405 72,-272 290,-642 478,-953 66,-110 129,-212 181,-300l-455 -23c-694,425 -1368,806 -2013,1125 -918,454 -1777,784 -2556,939 -639,128 -1082,-117 -890,-708 117,-363 445,-922 723,-1321 813,-1170 1961,-2111 3227,-2764 1287,-664 2761,-911 4202,-817 458,30 899,103 1330,210 1162,289 2136,745 3113,1431z"></path>
                                    <path className="fil0" d="M21013 8097c808,814 1443,1722 1865,2686 -556,225 -1798,623 -2547,457 -875,-195 -555,-1000 -296,-1565 112,-244 242,-483 386,-717 182,-297 367,-595 592,-861z"></path>
                                    <path className="fil1" d="M12925 9953c-211,333 -379,663 -455,984 -53,226 -73,433 54,594 261,334 1132,178 1709,14 1204,-343 2597,-1034 3733,-1690 983,-568 1874,-1103 2746,-1837 126,-107 113,-1 44,77 -375,427 -666,891 -937,1364 -284,497 -502,873 -538,1393 -56,785 376,1022 1031,1046 862,31 2051,-239 3056,-541 1221,-367 2455,-798 3649,-1244 99,-38 69,14 24,39l-1483 802c-1218,658 -2586,1207 -3910,1698 -1141,422 -2156,661 -3330,755 -1150,92 -1837,-190 -1450,-1353 129,-389 344,-779 582,-1116 72,-101 1,-91 -68,-52l-1783 1000c-1046,588 -2266,1108 -3456,1371 -405,90 -862,168 -1261,30 -414,-143 -484,-470 -296,-831 34,-65 72,-130 115,-195 39,-59 33,-89 -42,-64 -1041,349 -2092,599 -3160,668 -1516,97 -3409,-54 -4882,-378 1052,101 2118,166 3244,81 1014,-77 2052,-269 3034,-563 1439,-431 2832,-1149 4030,-2052z"></path>
                                </g>
                            </g>
                        </svg>
                        Bisista Pharma
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                        aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="about">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="products">Products</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="contact">Contact</Link>
                            </li>
                        </ul>
                        <span className="navbar-text d-flex">
                            {/* <select value={lang} onChange={(e)=>setLang(e.target.value)} className='form-select me-3'>
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                                <option value="fr">French</option>
                            </select> */}
                            <div id="google_translate_element"></div>
                            <button className="btn btn-sm btn-primary text-nowrap">Log in</button>
                        </span>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Nav
