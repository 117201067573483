const Capsules = () => {
	return (
		<>
			<h1>Capsules</h1>
			<div className="border shadow rounded table-responsive">
				<table className="table table-striped data-table">
					<thead>
						<tr>
							<th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
							<th><a onclick="sortTable(1,1)" className="cursor-pointer text-nowrap">HSN Code</a></th>
							<th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
						</tr>
					</thead>
					<tbody>
						<tr><td>OMEPRAZOLE CAPSULES 20MG</td><td>30042019</td><td>N(3X10)</td></tr>
						<tr><td>OMEPRAZOLE CAPSULES 40MG</td><td>30042019</td><td>N(2X7)</td></tr>
						<tr><td>PANTROPRAZOLE CAPSULES 40MG</td><td>30042019</td><td>N(3X10)</td></tr>
						<tr><td>FLUCANZOLE CAPSULES 50MG</td><td>30042019</td><td>1X3</td></tr>
						<tr><td>FLUCANZOLE CAPSULES 100MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>FLUCANZOLE CAPSULES 150MG</td><td>30042019</td><td>N1</td></tr>
						<tr><td>GANCICLOCIR CAPSULES 250MG</td><td>30042019</td><td>1X10</td></tr>
						<tr><td>GANCICLOCIR CAPSULES 500MG</td><td>30042019</td><td>1X10</td></tr>
						<tr><td>PREGABALIN CAPSULES 75MG</td><td>30042019</td><td>1X10</td></tr>
						<tr><td>PREGABALIN CAPSULES 100MG</td><td>30042019</td><td>1X10</td></tr>
						<tr><td>Celecoxib 200mg cap</td><td>30042019</td><td>1X10</td></tr>
						<tr><td>Amoxycillin 500 mg cap</td><td>30042019</td><td>1X20</td></tr>
						<tr><td>Amoxycillin 250 mg cap</td><td>30042019</td><td>1X20</td></tr>
						<tr><td>Esomeprazole 40 mg + Domperidone 30 mg SR Capsule</td><td>30042019</td><td>1X10</td></tr>
						<tr><td>Pantoprazole 40 mg + Domperidone 30 mg SR Caps</td><td>30042019</td><td>1X1O</td></tr>
						<tr><td>Omeprazole 20mg + Domperidone 30 mg SR Caps</td><td>30042019</td><td>1X1O</td></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
export default Capsules