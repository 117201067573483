const SuspensionSyrup = () => {
	return (
		<>
			<h1>Suspension and Syrup</h1>
			<div className="border shadow rounded table-responsive">
				<table className="table table-striped data-table">
					<thead>
						<tr>
							<th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
							<th><a onclick="sortTable(1,1)" className="cursor-pointer text-nowrap">HSN Code</a></th>
							<th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
						</tr>
					</thead>
					<tbody>
						<tr><td>LEVOCARNITINE ORAL SYRUP (1G/10ML)</td><td>30042019</td><td>N1</td></tr>
						<tr><td>AMOXICILLIN ORAL SUSPENSION (125MG/5ML)</td><td>30042019</td><td>N1</td></tr>
						<tr><td>IBUPROFEN ORAL SYRUP (100MG/5ML)</td><td>30042019</td><td>N1</td></tr>
						<tr><td>PARACETAMOL, PHENYLEPHRINE HYDROCHLORIDE &amp; CHLOPHINARAMINE MALEATE ORAL SYRUP(125MG/5MG/1MG/5ML)</td><td>30042019</td><td>N1</td></tr>
						<tr><td>BROMHEXINE HYDROCHLORIDE, SALBUTAMOL SULPHATE, GUAIPHENSIN &amp; METNTHOL ORAL SYRUP (2MG/1MG/50MG/0.5MG/5ML)</td><td>30042019</td><td>N1</td></tr>
						<tr><td>PARACETAMOL SYRUP</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CEFEXIME ORAL SUSPENSION</td><td>30042019</td><td>N1</td></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
export default SuspensionSyrup