const InfussionGlass = () => {
	return (
		<>
			<h1>Infussion (Glass Bottles)</h1>
			<div className="border shadow rounded table-responsive">
				<table className="table table-striped data-table">
					<thead>
						<tr>
							<th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
							<th><a onclick="sortTable(1,1)" clclassNameass="cursor-pointer text-nowrap">HSN Code</a></th>
							<th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
							<th><a onclick="sortTable(1,3)" className="cursor-pointer text-nowrap">Volume (ml)</a></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>5% DEXTROSE</td><td>30049099</td><td>15</td><td>500</td>
						</tr>
						<tr><td>DNS</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>NS</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>RL</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>10% DEXTROSE</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>JP-LYTE -M</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>JP-LYTE-P</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>Comp.Sod.Chlo.(Ringer)</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>5% DEXTROSE</td><td>30049099</td><td>9</td><td>1000</td></tr>
						<tr><td>DNS</td><td>30049099</td><td>9</td><td>1000</td></tr>
						<tr><td>NS</td><td>30049099</td><td>9</td><td>1000</td></tr>
						<tr><td>RL</td><td>30049099</td><td>9</td><td>1000</td></tr>
						<tr><td>10% DEXTROSE</td><td>30049099</td><td>9</td><td>1000</td></tr>
						<tr><td>JP-LYTE-M</td><td>30049099</td><td>9</td><td>1000</td></tr>
						<tr><td>JP-LYTE-P</td><td>30049099</td><td>9</td><td>1000</td></tr>
						<tr><td>0.33% DNS(0.33% NS &amp;D 5%)</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>0.45% DNS(0.45% NS &amp; D-2.5%)</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>0.45% DNS(0.45% NS &amp; D-5%)</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>0.45% DNS(0.225% NS &amp; D-5%)</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>DNS 10%</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>NS .45%</td><td>30049099</td><td>50</td><td>100</td></tr>
						<tr><td>NS .45%</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>NS 1.6% (Hypertonic Inj)</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>Sod.Lactet Inj.(1.85%) Molar</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>PARACETAMOL</td><td>30049099</td><td>50</td><td>100</td></tr>
						<tr><td>METRONIDAZOLE</td><td>30049022</td><td>50</td><td>100</td></tr>
						<tr><td>NS</td><td>30049099</td><td>50</td><td>100</td></tr>
						<tr><td>NS 3%</td><td>30049099</td><td>50</td><td>100</td></tr>
						<tr><td>20% MANNITOL</td><td>30049099</td><td>50</td><td>100</td></tr>

						<tr><td>20% MANNITOL</td><td>30049099</td><td>15</td><td>300</td></tr>
						<tr><td>20% MANNITOL</td><td>30049099</td><td>15</td><td>350</td></tr>
						<tr><td>25% DEXTROSE</td><td>30049099</td><td>50</td><td>100</td></tr>
						<tr><td>METRO+ DEXTROSE 5%</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>NS.45 %</td><td>30049099</td><td>50</td><td>100</td></tr>
						<tr><td>INVERT SUGAR</td><td>30049099</td><td>15</td><td>500</td></tr>
						<tr><td>MANITOL(10%) WITH GLYCERINE(10%)</td><td>30049099</td><td>50</td><td>100</td></tr>
						<tr><td>NS 3%</td><td>30049099</td><td>15</td><td>250</td></tr>
						<tr><td>50% DEXTROSE</td><td>30049099</td><td>50</td><td>100</td></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
export default InfussionGlass