const Contact = () => {
  return (
    <>
      <h1>Contact</h1>
      <div>
        <h4>Bisista Pharma Private Limited</h4>
        CIN: U24290HR2021PTC092216
        <h5 className="my-3">Corporate Address:</h5>Unit 1123, 11th Floor, Tower B-4, Spaze I-Tech Park, Sector 49, Sohna Road, Gurugram, Haryana, 122018, India
        <h5 className="my-3">Manufacturing Site 1:</h5>
        Plot No. – 37, Udyog Kendra Ext. – 2, Echotech – 3, Greater Noida, Uttar Pradesh 201306, India
        <h5 className="my-3">Manufacturing Site 2:</h5>
        I.P: 47, Khasra # 84M, Mantri Farm, Vill: Raipur, Tehshil: Bhagwanpur, Roorkee, Dist: Haridwar, Uttarakhand 247661, India

        <div className="my-3">
          <span className='me-2'>Telephone: <a href="tel:+911244104640">+91-124-4104640</a></span>
          <span className='me-2'>Mobile/WhatsApp: <a href="tel:+919319798206">+91 (931) 979 8206</a></span>
          <span className='me-2'>Email: <a href='mailto:jagannath@bisistapharma.com'>info@bisistapharma.com</a></span>
        </div>
      </div>
    </>
  )
}
export default Contact