const Drops = () => {
	return (
		<>
			<h1>Drops</h1>
			<div className="border shadow rounded table-responsive">
				<table className="table table-striped data-table">
					<thead>
						<tr>
							<th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
							<th><a onclick="sortTable(1,1)" className="cursor-pointer text-nowrap">HSN Code</a></th>
							<th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
						</tr>
					</thead>
					<tbody>
						<tr><td>VITAMIN D3 DROPS (10ML)</td><td>30042019</td><td>N1</td></tr>
						<tr><td>LEVOCERTIRIZINE DROPS (1.25MG/5ML)</td><td>30042019</td><td>N1</td></tr>
						<tr><td>SIMETHICONE DROPS</td><td>30042019</td><td>N1</td></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
export default Drops