import Header from '../header/header'
import Footer from '../footer/footer'
import { Outlet } from 'react-router-dom'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default Layout
