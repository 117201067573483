const Sachets = () => {
    return (
        <>
            <h1>Sachets</h1>
            <div className="border shadow rounded table-responsive">
                <table className="table table-striped data-table">
                    <thead>
                        <tr>
                            <th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
                            <th><a onclick="sortTable(1,1)" className="cursor-pointer text-nowrap">HSN Code</a></th>
                            <th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>PARACETAMOL, PHENIRAMINE MALEATE, PHENYLEPHRINE HYDROCHLORIDE &amp; ASCORBIC ACID POWDER(325MG/20MG/10MG/50MG) LEMON, ORANGE, STRAWBERY)</td><td>30042019</td><td>N1X10</td></tr>
                        <tr><td>L-ORNITHINE-L-ASPARATATE GRANULES</td><td>30042019</td><td>N1X10</td></tr>
                        <tr><td>POLYETHYLENE GLYCOL WITH ELECTROLYTES FOR ORLS SOLUTION</td><td>30042019</td><td>N1X10</td></tr>
                        <tr><td>CHOLESTYARAMINE FOR ORL SUSPENSION USP (4MG)</td><td>30042019</td><td>N1X10</td></tr>
                        <tr><td>FOSFOMYCIN TROMETAMOL GRANULES FOR ORAL SOLUTIONS (3MG)</td><td>30042019</td><td>N1X10</td></tr>
                        <tr><td>NIMESULIDE GRANUELS FOR ORAL SUSPENSION (100GM)</td><td>30042019</td><td>N1X10</td></tr>
                        <tr><td>CITACOLINE GRANULES FOR ORAL SULATION (500MG)</td><td>30042019</td><td>N1X10</td></tr>
                        <tr><td>AZITHOMYCIN POWDER FOR ORAL SUSPENSION (100,G)</td><td>30042019</td><td>N1X10</td></tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default Sachets