const Tablets = () => {
    return (
        <>
            <h1>Tablets</h1>
            <div className="border shadow rounded table-responsive">
                <table className="table table-striped data-table">
                    <thead>
                        <tr>
                            <th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
                            <th><a onclick="sortTable(1,1)" className="cursor-pointer text-nowrap">HSN Code</a></th>
                            <th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>Azithromycin 250 mg Tablet</td><td>30042019</td><td>1X6</td></tr>
                        <tr><td>Azithromycin 500 mg Tablet</td><td>30042019</td><td>1X6</td></tr>
                        <tr><td>Ciprofloxacin 500mg tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Ciprofloxacin 250mg tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>ROSUVASTATIN CALCIUM 10MG</td><td>30042019</td><td>3X10</td></tr>
                        <tr><td>ROSUVASTATIN CALCIUM 20MG</td><td>30042019</td><td>3X10</td></tr>
                        <tr><td>Metformin 500mg + Sitagliptin 50mg Tablet</td><td>30042019</td><td>3X10</td></tr>
                        <tr><td>Metformin 1000mg + Sitagliptin 50mg Tablet</td><td>30042019</td><td>3X10</td></tr>
                        <tr><td>CLOPIDOGREL 75MG</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>OLMESARTAN 20MG Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>OLMESARTAN 40 mg Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>TELMISARTAN 40 mg  tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>TELMISARTAN 80 mg  tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>LOSARTAN POTASSIUM 50 mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>LOSARTAN POTASSIUM 100 mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>AMLODIPINE 5 MG Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>AMLODIPINE 10 MG Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Levocetirizine 5 mg Tablet</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Levocetirizine 5 mg + Montelukast 10 mg Tablet</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Fexofenadine 120 mg + Montelukast 10 mg Tablet</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Fexofenadine 180 mg Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Esomeprazole 40 mg Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Glimepiride 1 mg  Tablet</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Glimepiride 4mg Tablet</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Metformin HCL 500 mg (ER) + Glimipride 1/2 mg Tablet (Bilayered Tablet)</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Metformin HCL 500 mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Metformin HCL 850 mg SR</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Metformin HCL 1000 mg SR</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>DEXAMETHASONEÂ&nbsp;Tablet 4 mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>DEXAMETHASONEÂ&nbsp;TabletÂ&nbsp;8 mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>SILDENAFIL CITRATE TabletÂ&nbsp;50 mg</td><td>30042019</td><td>1X4</td></tr>
                        <tr><td>SILDENAFIL CITRATEÂ&nbsp;TabletÂ&nbsp;100 mg</td><td>30042019</td><td>1X4</td></tr>
                        <tr><td>Cefixime 100 mg Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Cefixime 200 mg Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Cefuroxime 250mg Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Cefuroxime 500mg Tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Amoxy Clav 625 mg tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Amoxy Clav 375 mg tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Amoxy Clav 1000 mg tab</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Tadalafil Tablets USP 5mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Tadalafil Tablets USP 10mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Tadalafil Tablets USP 20mg</td><td>30042019</td><td>1X10</td></tr>
                        <tr><td>Albendazole Chewable Tablets 400mg</td><td>30042019</td><td>1X10</td></tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default Tablets