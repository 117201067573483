const About = () => {
	return (
		<>
			<h1>About</h1>
			<h2>BISISTA PHARMA PVT. LTD</h2>
			<div>
				<p>
					Welcome to Bisista Pharma, a distinguished name in the realm of pharmaceutical manufacturing and exports. 
					Established with a vision to provide high-quality healthcare solutions globally, we take pride in our commitment to excellence and adherence to the highest industry standards. 
					The product portfolio includes highly innovative prescription drugs in various therapeutic areas. 
					BISISTA PHARAMA is one of the top growing PHARMACEUTICAL Export House in INDIA.
				</p>
				<h4>Strengths:</h4>
				<ul>
					<li>Large capacities to produce bulk quantities.</li>
					<li>Innovated &amp; Sophisticated Packing Facilities in the local languages of the countries as per specific requirements.</li>
					<li>Commitment to excellence and quality.</li>
					<li>Dedicated and committed team of professionals.</li>
					<li>Experts in manufacturing, QC&amp;QA.</li>
					<li>Flexibility of batch sizes.</li>
					<li>Cost efficiency.</li>
					<li>Top experienced and well versed in dispatching export orders.</li>
				</ul>
				<h4>
					Manufacturing Facilities:
				</h4>
				<p>
					The company manufactures its products at different Ultra modern Facilities for manufacturing in India. All the Manufacturing Plants are WHO-GMP Certified fully equipped to manufacture:
				</p>
				<ul>
					<li>Injectables</li>
					<li>Tablets</li>
					<li>Capsules</li>
					<li>Ointments</li>
					<li>Eye &amp; Ear Drops</li>
					<li>Large Volume Parenterals</li>
				</ul>
				<h4>
					Mission:
				</h4>
				<ul>
					<li>To become a leading drug manufacturer &amp; Exporter of Pharmaceuticals from India with WHO GMP norms.</li>
					<li>To produce Life saving drugs at affordable prices with maximum reach.</li>
					<li>To be a reputed and reliable supplier of health care products and services to our customers throughout globally.</li>
					<li>To create and sustain a learning environment that nurtures innovation, leading to collective excellence.</li>
					<li>To be a leader in management practices and marketing excellence.</li>
					<li>To improve &amp; expand the range of services for our clients &amp; partners</li>
				</ul>
				<h4>
					Vision:
				</h4>
				<ul>
					<li>To empower lives globally by providing innovative and high-quality pharmaceutical solutions.</li>
					<li>Envisioning a world where access to high-quality healthcare is a fundamental right for everyone.</li>
					<li>Striving to lead the pharmaceutical industry through a commitment to innovation, integrity, and excellence.</li>
					<li>Dedication to shaping a healthier tomorrow through continuous advancements and pioneering approaches in healthcare.</li>
				</ul>
				<h4>Global Presence:</h4>
				<p>
					With a strong foothold in pharmaceuticals, we extend our reach to Latin American (LATAM) countries, African nations, Asian Countries and some Middle East countries, Also Products are Under Registration in Other Countries. Recognized and trusted in these regions, our products contribute to the well-being of diverse populations.
				</p>
				<h4>
					Message:
				</h4>
				<p>
					We're not just crafting pharmaceuticals; we're shaping a global legacy of trust and well-being. 
					Envisioning a world where healthcare knows no limits, we strive to redefine industry standards, shaping a healthier tomorrow for communities worldwide.
				</p>
				{/* <h4>Accreditations:</h4>
				<p>
					An ISO 9001-2008 Company @ WHO GMP Certified Plants! Audits successfully faced from:- 1. NIGERIA, 2. TAJIKISTAN, 3. IRAQ, 4. KYRGYZSTAN, 5. YAMEN 6. UZABEKISTAN.
				</p> */}
				<h4>Research & Development:</h4>
				<p>
					<span className="fw-bold">BISISTA PHARMA</span> has tie ups with strong and innovative Research and Development Centres, who have made significant breakthroughs in the development of various active pharmacological ingredients. From these centres research is continuously on-going for the development and marketing of additional products. Many of the other products under development in R&D are likely to go off patent in Europe and America which will substantially increase export sales over the next few years.
				</p>
				<h4>Quality Policy:</h4>
				<p>
					Quality is customer driven and not merely a departmental obligation. To ensure the total Quality Management, Company <span className="fw-bold">BISISTA PHARMA</span> provides special trainings and courses to its employees for Quality Assurance Checks while manufacturing and packaging of the export orders online. The QA department strictly follows all the recommendations specified in US Pharmacopeia, British, Russian and Indian Pharmacopeia’s for Raw material checks, in process checks, and finished goods testing. Raw materials are accepted for manufacturing only after found to be a pharmacopeia grade by the QA & QC Managers @ manufacturing facilities. BISISTA PHARMA reiterates its commitment to quality and strives to produce and launch high quality medicines.
				</p>
			</div>
		</>
	)
}
export default About