const CreamGel = () => {
	return (
		<>
			<h1>Cream and Gel</h1>
			<div className="border shadow rounded table-responsive">
				<table className="table table-striped data-table">
					<thead>
						<tr>
							<th><a onclick="sortTable(1,0)" className="cursor-pointer">Product</a></th>
							<th><a onclick="sortTable(1,1)" className="cursor-pointer text-nowrap">HSN Code</a></th>
							<th><a onclick="sortTable(1,2)" className="cursor-pointer text-nowrap">Pack Size</a></th>
						</tr>
					</thead>
					<tbody>
						<tr><td>CLOBETASOL CREAM BP 0.05% W/W</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CLOTRIMAZOLE CREAM BP 1% W/W</td><td>30042019</td><td>N1</td></tr>
						<tr><td>DOCLOFENAC DIETHYLE AMMOUNIUM, METHYLE SALICYLATE, MENTHOL&amp; LINSEED OIL GEL</td><td>30042019</td><td>N1</td></tr>
						<tr><td>KETOROLC TROMETHAMINE GEL 2% W/W</td><td>30042019</td><td>N1</td></tr>
						<tr><td>CLOBETASOLE PROPIONATE, KETOCONAZOLE &amp; NEOMYCIN SULPHATE CREAM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>NIFEDIPINE, LIDOCAINE WITH ISSOSORBIBIDE DINITRATE GEL</td><td>30042019</td><td>N1</td></tr>
						<tr><td>BECLOMERHASONE DIPROPIONATE, PHEBYLEPHRINE HYDROCHLORIDE, LIGNOCAINE HYDROCHLORIDE &amp; CHLOROCRESOL CREAM</td><td>30042019</td><td>N1</td></tr>
						<tr><td>METRONIDAZOLE &amp; CHLORHEXIDINE GLUCONATE GEL 10/15MG</td><td>30042019</td><td>N1</td></tr>
					</tbody>
				</table>
			</div>
		</>
	)
}
export default CreamGel