import Header from '../header/header'
import Footer from '../footer/footer'
import { Outlet } from 'react-router-dom'

const Container = ({ children }) => {
  return (
    <>
      <Header />
      <header className="slide-down text-white" style={{minHeight:"20rem"}}>
      </header>
      <div className="container bg-white py-4 px-lg-5 shadow" style={{marginTop:'-10rem'}}>
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default Container
