import ProductList from "../home/productlists"

const Products = () => {
    return (
        <>
            <h1>Products</h1>
            <div>
                <ProductList/>
            </div>
        </>
    )
}
export default Products