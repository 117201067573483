import './footer.css'

const Footer = () => {
    return (
        <footer className="py-3 py-lg-5 px-lg-5">
            <div className="container">
                <div className="d-flex flex-wrap">
                    <div className="flex-grow-1 text-center">
                        <a className="navbar-brand" href='/'>
                            Bisista Pharma Private Limited
                        </a>
                        <h6 className='mt-3'>CIN: U24290HR2021PTC092216</h6>
                        <div className="my-3">
                            <h5>Corporate Address:</h5>
                            <small>
                                {/* <div className='mb-3'>1029, 10th Floor, Tower A, Spaze I-Tech Park, Sector 49, Sohna Road, Gurgaon, Haryana - 122002 India</div> */}
                                <div className='mb-3'>Unit 1123, 11th Floor, Tower B-4, Spaze I-Tech Park, Sector 49, Sohna Road, Gurugram, Haryana, 122018, India</div>
                                <span className='mx-2'>Telephone: <a href="tel:+911244104640">+91-124-4104640</a></span>
                                <span className='mx-2'>Mobile/WhatsApp: <a href="tel:+919319798206">+91 (931) 979 8206</a></span>
                                <span className='mx-2'>Email: <a href='mailto:jagannath@bisistapharma.com'>info@bisistapharma.com</a></span>
                            </small>
                        </div>
                        <div className="my-3">
                            <h5>Manufacturing Address 1:</h5>
                            Plot No. – 37, Udyog Kendra Ext. – 2, Echotech – 3, Greater Noida, Uttar Pradesh 201306, India
                            <h5 className="my-3">Manufacturing Address 2:</h5>
                            I.P: 47, Khasra # 84M, Mantri Farm, Vill: Raipur, Tehshil: Bhagwanpur, Roorkee, Dist: Haridwar, Uttarakhand 247661, India

                        </div>
                    </div>
                    {/* <div className="d-flex mt-4 mt-md-0">
                        <div className="px-md-5">
                            <h6 className="mb-3">Bisista Pharma</h6>
                            <div className="d-flex flex-column ps-1">
                                <a href='privacy'>Privacy</a>
                                <a href='terms'>Terms</a>
                                <a href='policies'>Policies</a>
                                <a href='feedback'>Feedback</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}
export default Footer
