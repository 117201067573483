import './header.css'
import Nav from '../nav/nav'

const Header = () => {
    return (
        <>
            <Nav />
        </>
    )
}
export default Header
