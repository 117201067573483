import { Route, Routes } from 'react-router-dom'
import Layout from './components/shared/layout/layout'
import Container from './components/shared/layout/container'
import Home from './components/home/home'
import About from './components/pages/about'
import Contact from './components/pages/contact'
import Products from './components/pages/products'
import Product from './components/products/product'
import Tablets from './components/products/tablets'
import Capsules from './components/products/capsules'
import Injections from './components/products/injections'
import Sachets from './components/products/sachets'
import Drops from './components/products/drops'
import CreamGel from './components/products/cream-gel'
import SuspensionSyrup from './components/products/suspension-syrup'
import InfussionGlass from './components/products/infussion-glass'
import InfussionFFS from './components/products/infussion-ffs'

function App() {
  return (
    <Routes>
      <Route element={<Container />}>
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/products/tablets" element={<Tablets />} />
        <Route path="/products/capsules" element={<Capsules />} />
        <Route path="/products/injections" element={<Injections />} />
        <Route path="/products/sachets" element={<Sachets />} />
        <Route path="/products/drops" element={<Drops />} />
        <Route path="/products/cream-and-gel" element={<CreamGel />} />
        <Route path="/products/suspension-and-syrup" element={<SuspensionSyrup />} />
        <Route path="/products/infusion-glass-bottles" element={<InfussionGlass />} />
        <Route path="/products/infusion-ffs-bottles" element={<InfussionFFS />} />

        <Route path="*" component={<Home />} status={404} />
      </Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
      </Route>
    </Routes>
  )
}

export default App
