const Product = () => {
    return (
        <>
            <h1>Product</h1>
            <div>
                
            </div>
        </>
    )
}
export default Product