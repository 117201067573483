import { Link } from "react-router-dom";
import ProductList from "./productlists";

const Home = () => {

	return (
		<>
			<header className="slide-down text-white d-flex flex-column justify-content-center bg-white">
				<div className='container'>
					<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
						<div className="carousel-indicators">
							<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
							<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
							<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
							<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
						</div>
						<div className="carousel-inner">
							<div className="carousel-item text-center active">
								<img src="img/homebanner1.jpg" className="img-fluid"/>
								{/* <img src="img/lab_640.png" className="img-fluid"/> */}
							</div>
							<div className="carousel-item text-center">
								<img src="img/homebanner2.jpg" className="img-fluid"/>
							</div>
							<div className="carousel-item text-center">
								<img src="img/homebanner3.jpg" className="img-fluid"/>
							</div>
							<div className="carousel-item text-center">
								<img src="img/homebanner4.jpg" className="img-fluid"/>
							</div>
						</div>
						<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
							<span className="carousel-control-prev-icon" aria-hidden="true"></span>
							<span className="visually-hidden">Previous</span>
						</button>
						<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
							<span className="carousel-control-next-icon" aria-hidden="true"></span>
							<span className="visually-hidden">Next</span>
						</button>
					</div>
				</div>
			</header>

			<div className="container">
				<div className="cards">
					<img className="img-fluid" src="img/ACETYLCYSTEINE_INJ_640x360-min.jpg"/>
					<img className="img-fluid" src="img/neostigmine_2.5_inj-min.jpg"/>
					<img className="img-fluid" src="img/ACETYLCYSTEINE_INJ_640x360-min.jpg"/>
					<img className="img-fluid" src="img/neostigmine_2.5_inj-min.jpg"/>
				</div>
				<ProductList/>
			</div>
		</>
	)
}
export default Home